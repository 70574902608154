import { useEffect, useState } from "react";
import NextLink from "next/link";
import numeral from "numeral";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { ArrowRight as ArrowRightIcon } from "../../../icons/arrow-right";
import { PencilAlt as PencilAltIcon } from "../../../icons/pencil-alt";
import { getInitials } from "../../../utils/get-initials";
import { Scrollbar } from "../../scrollbar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../deleteDialog";
import { toast } from "react-hot-toast";
import { deleteAdmin, deleteMultipleAdmin } from "../../../_api/admin";
import { useQueryClient } from "react-query";

export const AdminListTable = (props) => {
  const queryClient = useQueryClient();

  const {
    admins,
    adminLoading,
    customersCount,
    onPageChange,
    onRowsPerPageChange,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [adminId, setAdminId] = useState();
  const [lastSelectedCheckboxIndex, setLastSelectedCheckboxIndex] =
    useState(null);

  useEffect(() => {
    if (selectedCustomers.length) {
      setSelectedCustomers([]);
    }
  }, [admins]);

  const handleDeleteAdmin = async () => {
    toast.loading("Deleting...");
    try {
      await deleteAdmin(adminId);
      queryClient.invalidateQueries("get-Admins");
      toast.dismiss();
      toast.success("Admin deleted!");
    } catch (err) {
      toast.dismiss();
      toast.error(err.response.data.message);
    }
  };

  const handleDeleteMultipleAdmins = async () => {
    toast.loading("Deleting...");
    try {
      await deleteMultipleAdmin(selectedCustomers);
      queryClient.invalidateQueries("get-Admins");
      toast.dismiss();
      toast.success("Admin deleted!");
    } catch (err) {
      toast.dismiss();
      toast.error(err.response.data.message);
    }
  };

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(
      event.target.checked ? admins.map((user) => user.id) : []
    );
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) =>
        prevSelected.filter((id) => id !== customerId)
      );
    }
  };

  const handleMouseDown = (event, userId) => {
    if (event.shiftKey && lastSelectedCheckboxIndex) {
      const lastClickedIndex = admins.findIndex(
        (admin) => admin.id === lastSelectedCheckboxIndex
      );
      const currentClickedIndex = admins.findIndex(
        (admin) => admin.id === userId
      );
      const selected = admins
        .slice(
          Math.min(lastClickedIndex, currentClickedIndex),
          Math.max(lastClickedIndex, currentClickedIndex)
        )
        .filter((admin) => !admin.addedToTournament)
        .map((admin) => admin.id);
      setSelectedCustomers((prevSelected) => [
        ...new Set([...prevSelected, ...selected]),
      ]);
    }
    setLastSelectedCheckboxIndex(userId);
  };

  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers =
    selectedCustomers.length > 0 && selectedCustomers.length < admins.length;
  const selectedAllCustomers = selectedCustomers.length === admins?.length;

  return (
    <>
      {adminLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : admins?.length ? (
        <div {...other}>
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "neutral.800" : "neutral.100",
              display: enableBulkActions ? "block" : "none",
              px: 2,
              py: 0.5,
            }}
          >
            <Checkbox
              checked={selectedAllCustomers}
              indeterminate={selectedSomeCustomers}
              onChange={handleSelectAllCustomers}
            />
            <Button
              size="small"
              sx={{ ml: 2 }}
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteType("multiple");
              }}
            >
              Delete
            </Button>
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead
                sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
              >
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllCustomers}
                      indeterminate={selectedSomeCustomers}
                      onChange={handleSelectAllCustomers}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins?.map((admin) => {
                  const isCustomerSelected = selectedCustomers.includes(
                    admin?.id
                  );
                  return (
                    <TableRow
                      hover
                      key={admin?.id}
                      selected={isCustomerSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isCustomerSelected}
                          onChange={(event) =>
                            handleSelectOneCustomer(event, admin?.id)
                          }
                          value={isCustomerSelected}
                          onMouseDown={(e) => handleMouseDown(e, admin?.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <Avatar
                            src={admin?.avatar}
                            sx={{
                              height: 42,
                              width: 42,
                            }}
                          >
                            {getInitials(admin?.firstName)}
                          </Avatar>
                          <Box sx={{ ml: 1 }}>
                            <NextLink
                              href={`/dashboard/admins/${admin?.id}`}
                              passHref
                            >
                              <Link color="inherit" variant="subtitle2">
                                {admin?.firstName.toUpperCase() +
                                  " " +
                                  admin?.lastName.toUpperCase()}
                              </Link>
                            </NextLink>
                            <Typography color="textSecondary" variant="body2">
                              {admin?.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {admin?.isSuperAdmin ? "Super Admin" : "Admin"}
                      </TableCell>
                      <TableCell align="right">
                        {!admin?.isSuperAdmin && (
                          <>
                            <Tooltip title="Delete" placement="top">
                              <IconButton
                                component="a"
                                onClick={() => {
                                  setAdminId(admin?.id);
                                  setOpenDeleteDialog(true);
                                  setDeleteType("single");
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <NextLink
                              href={`/dashboard/admins/${admin?.id}/edit`}
                              passHref
                            >
                              <Tooltip title="Edit" placement="top">
                                <IconButton component="a">
                                  <PencilAltIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </NextLink>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePagination
            component="div"
            count={customersCount}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>Admins not available.</h4>
        </Box>
      )}

      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        setSelected={setAdminId}
        handleDelete={handleDeleteAdmin}
        deleteType={deleteType}
        handleDeleteMultiple={handleDeleteMultipleAdmins}
      />
    </>
  );
};

AdminListTable.propTypes = {
  admins: PropTypes.array.isRequired,
  customersCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
